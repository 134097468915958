import "../styles/main.scss";
$(function() {
    $('.toggle').click(function() {
        $(this).toggleClass('active');

        if ($(this).hasClass('active')) {
            $('.header-nav').addClass('active'); //クラスを付与
        } else {
            $('.header-nav').removeClass('active'); //クラスを外す
        }
    });
});

$(document).ready(function() {
    if (location.pathname != "/") {
        $('.header-nav__item a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');
    } else $('.header-nav__item a:eq(0)').addClass('active');
});

$(function() {
    $('a[href^="#"]').click(function() {
        var speed = 500;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $("html, body").animate({ scrollTop: position }, speed, "swing");
        return false;
    });
});
